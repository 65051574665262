import { Card, Col, Collapse, DatePicker, Divider, Form, Icon, Icons, Input, List, Radio, Row, SaveButton, Select, Table, Typography, useSelect, useTable } from "@pankod/refine-antd";
import {
  CrudFilters,
  IResourceComponentsProps,
  useOne
} from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { GenAISVG } from "components/svgs/genAISvg";
import dayjs from "dayjs";
import { IIqMetadata, IIqResult, ILookup } from "interfaces";
import React, { useState } from "react";
import { rowGutter, threeColumnLayout } from "scripts/layout";
import { DATAPROVIDER_AI, DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const IqList: React.FC<IResourceComponentsProps> = () => {

  // define state variable
  const [resource, setResource] = useState<string>(RESOURCE_PATH.INTAKE_ACTIVITY);
  const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(false);


  const { data: entityMetadata } = useOne<IIqMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.IQ,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const metaConfig = entityMetadata?.data?.config;

  const { searchFormProps, tableQueryResult } = useTable<IIqResult>({
    dataProviderName: DATAPROVIDER_AI,
    resource: resource,
    permanentFilter: [
      {
        field: "_aiAction",
        operator: "eq",
        value: "summarize"
      }
    ],
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    defaultSetFilterBehavior: "replace",
    //initialPageSize: 10,
    onSearch: (values: any) => {
      const filters: CrudFilters = [];
      const { range, updatedBy, tone, length, overrideprompt } = values;
      const [startDate, endDate] = range || [];

      if (startDate && endDate) {
        filters.push({
          field: resource === RESOURCE_PATH.POSITION ? "updatedAt" : "activitydate",
          operator: "gte",
          value: dayjs(startDate).startOf("day").toISOString()
        });
        filters.push({
          field: resource === RESOURCE_PATH.POSITION ? "updatedAt" : "activitydate",
          operator: "lte",
          value: dayjs(endDate).endOf("day").toISOString()
        });
      }
      filters.push({
        field: "updatedBy",
        operator: "eq",
        value: updatedBy
      });
      filters.push({
        field: "_tone",
        operator: "eq",
        value: tone
      });
      filters.push({
        field: "_length",
        operator: "eq",
        value: length
      });

      if (metaConfig?.experimental === true && overrideprompt) {
        document.cookie = `overrideprompt=${overrideprompt}`;
        // filters.push({
        //   field: "_overrideprompt",
        //   operator: "eq",
        //   value: overrideprompt
        // });
      }
      return filters;
    },
    queryOptions: {
      enabled: !!resource && isQueryEnabled,
      onSettled: () => setIsQueryEnabled(false),
      //enabled: false,
    },

  });

  const { selectProps: userList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "stateManager.state",
      operator: "eq",
      value: "active"
    }],
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });

  const summary = (tableQueryResult?.data?.data as any) as IIqResult;

  return (
    <List headerProps={{ title: "Helium IQ", subTitle: "Generate insights using AI" }}
      headerButtons={[]}
    >
      <Card>
        <Form {...searchFormProps} layout="vertical" autoComplete="off"
          initialValues={{
            entity: RESOURCE_PATH.INTAKE_ACTIVITY,
            tone: "formal",
            length: "brief",
          }}
        >
          <Row gutter={rowGutter}>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Form.Item
                label="Generate insights for"
                name="entity"
                rules={[
                  {
                    required: true,
                    whitespace: true
                  }
                ]}
              >
                <Radio.Group optionType="button" buttonStyle="solid" value={resource}
                  onChange={(e) => setResource(e.target.value)}
                  options={[
                    { label: "Engage AIT", value: RESOURCE_PATH.INTAKE_ACTIVITY },
                    { label: "Resourcing", value: RESOURCE_PATH.POSITION },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Form.Item
                label="Tone"
                name="tone"
              >
                <Radio.Group optionType="button" buttonStyle="solid"
                  options={[
                    { label: <><Icons.ShoppingOutlined /> Formal</>, value: "formal" },
                    { label: <><Icons.SmileOutlined /> Casual</>, value: "casual" },
                    { label: <><Icons.AimOutlined /> Direct</>, value: "direct" },
                    { label: <><Icons.EditOutlined /> Poem</>, value: "poem" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" {...threeColumnLayout}>
              <Form.Item
                label="Length"
                name="length"
              >
                <Radio.Group optionType="button" buttonStyle="solid"
                  options={[
                    { label: <><Icons.LineOutlined /> Brief</>, value: "brief" },
                    { label: <><Icons.MenuOutlined /> Detailed</>, value: "detailed" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          {(resource === RESOURCE_PATH.INTAKE_ACTIVITY || resource === RESOURCE_PATH.POSITION) && <>
            <Row gutter={rowGutter}>
              <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                  label="Date Range"
                  name="range"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      type: 'array'
                    }
                  ]}
                >
                  <DatePicker.RangePicker presets={[
                    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
                    { label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
                    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
                    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
                  ]}
                    disabledDate={(current) => current && current > dayjs().endOf('day')}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" {...threeColumnLayout}>
                <Form.Item
                  label="Updated By"
                  name="updatedBy"
                  tooltip="To look up any missing name type the name of the person and then select"
                >
                  <Select allowClear mode="multiple" //style={{ maxWidth: "286px" }}
                    {...userList}
                  >
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </>}

          {metaConfig?.experimental === true && <>
            <Divider >Experimental</Divider>
            <Form.Item
              label="Override system prompt"
              name="overrideprompt"
              tooltip="Use the system variables - $JSONData$, $Tone$ and $Length$"
              rules={[
                {
                  required: false,
                  whitespace: true,
                  min: 15
                },
              ]}
            >
              <Input.TextArea rows={4} placeholder="System prompt for Azure OpenAI" />
            </Form.Item>
          </>}

          <SaveButton icon={<Icon component={GenAISVG}></Icon>} htmlType="submit" type="primary"
            loading={tableQueryResult.isFetching}
            onClick={() => {
              searchFormProps?.form?.validateFields().then(async () => {
                setIsQueryEnabled(true);
                searchFormProps?.form?.submit();
              })
            }}>Generate</SaveButton>
        </Form>
      </Card>

      {(summary || tableQueryResult.isFetching) && <>
        <Card style={{ marginTop: "25px" }}
          loading={tableQueryResult?.isFetching} size="small"
          title={<><Icon component={GenAISVG}></Icon><Typography.Text style={{ marginLeft: 10, fontSize: 20 }} >Insights</Typography.Text></>}
          extra={<Typography.Text type="secondary">AI-generated content may be incorrect</Typography.Text>}>
          <div className="container" data-color-mode={getAppTheme()}>
            <MDEditor.Markdown source={summary?.result ?? summary?.message} />
          </div>
          <Divider style={{ marginBottom: 10 }} />
          <Typography.Text type="secondary">
            {summary?.usage?.total_tokens
              ? `Total ${summary?.usage?.total_tokens} tokens | `
              : ""}
            {summary?.timestamp
              ? `Generated on ${dayjs(summary?.timestamp).format("lll")}`
              : ""}
          </Typography.Text>
        </Card>

        {summary?.data &&
          !tableQueryResult.isFetching &&
          <Collapse style={{ marginTop: 10 }} size="small">
            <Collapse.Panel header={<><Icons.DatabaseOutlined /><Typography.Text style={{ marginLeft: 10 }}>Data</Typography.Text></>} key="1">
              <Table dataSource={summary?.data} size="small" //loading={tableQueryResult.isFetching}
                //pagination={false}
                style={{ overflowX: "auto" }}>
                {summary?.data && Object.keys(summary?.data[0]).map((key: string) => (
                  <Table.Column title={key} dataIndex={key} key={key} />
                ))}
              </Table>
            </Collapse.Panel>
          </Collapse>
        }
      </>
      }
    </List>
  );
};
